import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header>
    <a href="/">
      <StaticImage src="../assets/logo.svg" alt="Löfbergs" />
    </a>
  </header>
)

export default Header
