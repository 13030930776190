/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import video from "../../static/inferno.mp4"
import poster from "../../static/poster.png"
import Header from "./header"
import "./layout.scss"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const AutoPlayVideo = () => {
    return (
      <div className="videoContainer">
        <div
          dangerouslySetInnerHTML={{
            __html: `   <video
    loop
    muted
    playsInline
    style="width: 100%;"
    alt="video"
    className="video"
    preload="true"
    type="video/mp4"
    autoPlay
    id="video"
    poster=${poster}
  >
    <source src=${video} />
  </video>`,
          }}
        />
        <div className="title">
          <h1>VAHVIMMISTA VAHVIMMILLE</h1>
        </div>
      </div>
    )
  }
  return (
    <>
      <title>{data.site.siteMetadata?.title}</title>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        {AutoPlayVideo()}

        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
