import * as React from "react"
// import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <a target="_blank" href="https://www.lofbergs.fi/evasteasetukset">
      Evästeseloste
    </a>
    {/* <a target="_blank" href="/ehdot">
      Kilpailun säännöt
    </a> */}
  </footer>
)

export default Footer
